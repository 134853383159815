@import "../../../styles/helpers";

.section {
    position: relative;
    @include m {
        padding-top: 0;
        background: none; } }

.container {
    @include m {
        padding: 0 16px; } }

.gallery {
    position: absolute;
    top: 50%;
    left: calc(50% - 640px);
    width: 624px;
    transform: translateY(-50%);
    pointer-events: none;
    @include x {
        left: calc(50% - 610px);
        width: 580px; }
    @include d {
        left: calc(50% - 560px); }
    @include m {
        position: relative;
        top: auto;
        left: 50%;
        width: 500px;
        transform: translate(-50%,0); } }

.preview {
    &:first-child {
        position: relative;
        z-index: 2; }
    &:nth-child(2) {
        top: 14%;
        right: 13%;
        z-index: 3;
        width: 29.5%; }
    &:nth-child(3) {
        top: 46%;
        left: 15%;
        z-index: 1;
        width: 17%; }
    &:nth-child(4) {
        right: 3%;
        bottom: 17%;
        z-index: 1;
        width: 33.5%; }
    &:not(:first-child) {
        position: absolute; }
    img {
        width: 100%; } }

.wrap {
    position: relative;
    z-index: 3;
    max-width: 545px;
    margin-left: auto;
    padding: 48px;
    background: $neutrals8;
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
    border-radius: 20px;
    @include x {
        max-width: 500px; }
    @include d {
        max-width: 416px;
        padding: 32px; }
    @include dark {
        background: $neutrals1; } }

.title {
    margin-bottom: 24px; }

.info {
    margin-bottom: 24px;
    color: $neutrals4; }

.list {
    margin-bottom: 24px; }

.item {
    display: flex;
    padding: 24px;
    border-radius: 12px;
    cursor: pointer;
    transition: box-shadow .2s;
    &:hover {
        box-shadow: inset 0 0 0 2px $neutrals6;
        @include dark {
            box-shadow: inset 0 0 0 2px $neutrals3; } }
    &:not(:last-child) {
        margin-bottom: 4px; } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 24px;
    border-radius: 50%; }

.details {
    flex-grow: 1; }

.subtitle {
    margin-bottom: 4px;
    font-weight: 600; }

.content {
    max-width: 260px;
    @include caption-1;
    color: $neutrals4; }

.note {
    margin-bottom: 24px;
    margin-right: -10px;
    @include caption-1;
    color: $neutrals4;
    @include m {
        margin: 0 0 24px;
        text-align: center; }
    strong {
        font-weight: 600; } }

.green {
    color: $green; }

.black {
    color: $neutrals2;
    @include dark {
        color: $neutrals8; } }

.btns {
    text-align: right; }

.button {
    @include m {
        width: 100%; } }
