@import "../../../styles/helpers";

.container {
    display: flex;
    align-items: center;
    max-width: 100%;
    padding: 0;
    @include m {
        display: block; } }

.col {
    flex: 0 0 50%;
    &:first-child {
        @include d {
            flex: 0 0 40%; }
        @include t {
            flex: 0 0 45%; } }
    &:nth-child(2) {
        padding: 0 64px 0 106px;
        @include x {
            padding: 0 40px 0 80px; }
        @include d {
            flex: 0 0 60%; }
        @include t {
            flex: 0 0 55%;
            padding: 0 40px 0 40px; }
        @include m {
            padding: 48px 32px 64px; } } }

.wrap {
    max-width: 450px; }

.stage {
    margin-bottom: 21px; }

.title {
    position: relative;
    margin-bottom: 35px;
    padding-bottom: 36px;
    @include m {
        margin-bottom: 32px;
        padding-bottom: 32px; }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 128px;
        height: 1px;
        background: $neutrals6;
        @include dark {
            background: $neutrals3; } } }

.text {
    margin-bottom: 40px;
    color: $neutrals4;
    @include m {
        margin-bottom: 32px; } }

.subscription {
    max-width: 300px; }

.preview {
    @include rmin(1800) {
        height: 880px; }
    img {
        width: 100%;
        max-height: 100%;
        object-fit: cover; } }

